import { E164Number } from 'libphonenumber-js/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import styled from 'styled-components';

import { getCountry } from '../../utils/Country';
import Label from './Label';

interface Props {
    placeholder: string;
    value: E164Number | undefined;
    onChange: (e: E164Number) => void;
}

function StyledPhoneInput({ placeholder, value, onChange }: Props) {
    const [t] = useTranslation('workWithUs');

    return (
        <Container>
            <Label large={false} label={`${t('contact_phone')}*`} />
            <PhoneInput
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                defaultCountry={getCountry()}
            />
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export default StyledPhoneInput;
