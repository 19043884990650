import React from 'react';
import styled from 'styled-components';

import { MOBILE_WIDTH } from '../../hooks/useWindowDimensions';

interface Props {
    title: string;
}

function SectionTitle({ title }: Props) {
    return <Text>{title}</Text>;
}

const Text = styled.p`
    font-size: 80px;

    @media (max-width: ${MOBILE_WIDTH}px) {
        font-size: 25px;
        margin: 50px 0 0 0;
    }
`;

export default SectionTitle;
