import React from 'react';
import styled from 'styled-components';

import { Colors } from '../../utils/Colors';

interface Props {
    text: string;
    onClick: () => void;
}

function L5button({ text, onClick }: Props) {
    return (
        <Container onClick={onClick}>
            <Text>{text}</Text>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 300px;
    border-radius: 20px;
    background-color: ${Colors.primary};

    &:hover {
        opacity: 0.5;
        transition: all ease-in-out 0.5s;
        cursor: pointer;
    }
`;

const Text = styled.p`
    color: ${Colors.white};
`;

export default L5button;
