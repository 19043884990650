import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import { useAppSelector } from '../../../hooks/useAppSelector';
import useWindowDimensions, {
    MOBILE_WIDTH,
} from '../../../hooks/useWindowDimensions';
import { selectIsOpened } from '../../../store/contact-form/contactFormSlice';
import { Colors } from '../../../utils/Colors';
import WorkWithUsContent from './WorkWithUsContent';

function WorkWithUs() {
    const isOpened = useAppSelector(selectIsOpened);
    const { width, height } = useWindowDimensions();

    const animation = () => {
        if (isOpened && width < 1280) {
            return { height, width: width, borderBottomLeftRadius: 0 };
        } else if (isOpened) {
            return { height: '800px', width: '85%' };
        }

        return '';
    };

    return (
        <Container
            animate={animation()}
            transition={{
                duration: 2,
                ease: 'anticipate',
                delayChildren: 1000,
            }}
        >
            <AnimatePresence>
                {isOpened && <WorkWithUsContent />}
            </AnimatePresence>
        </Container>
    );
}

const Container = styled(motion.div)`
    display: flex;
    justify-content: start;
    align-items: start;
    position: fixed;
    height: 0;
    width: 0;

    background-color: ${Colors.secondary};

    z-index: 1000;

    border-bottom-left-radius: 1000px;

    right: 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        top: -20px;
        margin: 8vh 0 0 0;
        padding: 0 0 -100px 0;
    }
`;

export default WorkWithUs;
