import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { BudgetOption } from '../../enums/BudgetOption';
import { useAppSelector } from '../../hooks/useAppSelector';
import { MOBILE_WIDTH } from '../../hooks/useWindowDimensions';
import { selectSelectedBudget } from '../../store/contact-form/contactFormSlice';
import Label from './Label';

export type Option = { key: BudgetOption; value: string; disabled?: boolean };

interface Props {
    value: string;
    options: Option[];
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

function Select({ value, options, onChange }: Props) {
    const [t] = useTranslation('workWithUs');
    const selectedValue = useAppSelector(selectSelectedBudget);

    return (
        <Container>
            <Label label={t('budget')} />
            <Selector
                onChange={onChange}
                value={value}
                aria-placeholder={'asdasd'}
                selectedValue={selectedValue}
            >
                {options.map((option) => (
                    <option key={option.key}>{option.value}</option>
                ))}
            </Selector>
        </Container>
    );
}

const MyOption = styled.option`
    color: red;
`;

const A = styled.p`
    color: red;
`;

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    min-width: 300px;
`;

const Selector = styled.select<{ selectedValue: BudgetOption }>`
    height: 40px;
    border-radius: 5px;
    padding: 0 0 0 10px;
    transition: all ease-in-out 0.5s;
    outline: none;
    margin: 0 10px 0 0;
    color: ${(props) =>
        props.selectedValue === BudgetOption.SELECT_OPTION ? 'grey' : ''};

    @media (max-width: ${MOBILE_WIDTH}px) {
        margin: 0;
    }
`;

export default Select;
