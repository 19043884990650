import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoClose } from 'react-icons/io5';
import styled from 'styled-components';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useWindowDimensions, {
    MOBILE_WIDTH,
} from '../../../hooks/useWindowDimensions';
import {
    close,
    selectIsTakingAppointment,
} from '../../../store/contact-form/contactFormSlice';
import { Colors } from '../../../utils/Colors';
import Appointment from '../../ui-elements/appointment/Appointment';
import ContactForm from './ContactForm';
import LeftSection from './LeftSection';
import RightSection from './RightSection';

function WorkWithUsContent() {
    const isTakingAppointment = useAppSelector(selectIsTakingAppointment);
    const { width } = useWindowDimensions();
    const [t] = useTranslation('workWithUs');
    const dispatch = useAppDispatch();

    return (
        <Content
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            width={width - 2}
        >
            <LeftSection>
                <CloseIcon onClick={() => dispatch(close())} />

                <LetsTalk>
                    {isTakingAppointment ? t('appointment') : t('lets_talk')}
                </LetsTalk>
            </LeftSection>

            <RightSection>
                <TitleContainer>
                    <Title>
                        {isTakingAppointment
                            ? t('select_time_slot')
                            : t('ready')}
                    </Title>
                </TitleContainer>

                {isTakingAppointment ? <Appointment /> : <ContactForm />}
            </RightSection>
        </Content>
    );
}

const Content = styled(motion.div)<{ width: number }>`
    display: flex;
    flex: 1;
    flex-direction: row;

    padding: 40px 0 0 0;

    height: 100%;

    opacity: 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        width: ${(props) => `${props.width}px`};
        flex-direction: column;
        padding: 20px 0 0 0;
    }
`;

const CloseIcon = styled(IoClose)`
    width: 60px;
    height: 60px;
    margin: 0;
    color: ${Colors.white};

    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        width: 30px;
        height: 30px;
    }
`;

const LetsTalk = styled.p`
    font-size: 50px;
    color: ${Colors.white};
    margin: 0 0 0 20px;

    @media (max-width: ${MOBILE_WIDTH}px) {
        font-size: 25px;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    width: 100%;
`;

const Title = styled.p`
    font-size: 20px;
    color: ${Colors.white};

    @media (max-width: ${MOBILE_WIDTH}px) {
        font-size: 17px;
        padding: 0 20px;
        text-align: center;
    }
`;

export default WorkWithUsContent;
