import React, { ChangeEvent, HTMLInputTypeAttribute } from 'react';
import styled from 'styled-components';

import { MOBILE_WIDTH } from '../../hooks/useWindowDimensions';
import { Colors } from '../../utils/Colors';
import Label from './Label';

export type TextInputType = {
    label: string;
    placeholder?: string;
    value: string;
    onChange: (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
    ) => void;
    large?: boolean;
    type?: HTMLInputTypeAttribute;
    isMissingValue?: boolean;
};

function TextInput({
    label,
    placeholder,
    value,
    onChange,
    large,
    isMissingValue,
}: TextInputType) {
    return (
        <Container>
            <Label large={large} label={label} />

            {large ? (
                <InputArea
                    placeholder={placeholder}
                    onChange={(e) => onChange(e)}
                    isMissingValue={isMissingValue}
                    value={value}
                />
            ) : (
                <Input
                    placeholder={placeholder}
                    onChange={(e) => onChange(e)}
                    isMissingValue={isMissingValue}
                    value={value}
                />
            )}
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    min-width: 300px;
`;

const Input = styled.input<{ isMissingValue: boolean | undefined }>`
    border-radius: 5px;
    border: ${(props) =>
        props.isMissingValue
            ? `1px solid ${Colors.red}`
            : `1px solid ${Colors.white}`};
    height: 40px;
    outline: none;
    padding: 0 0 0 10px;
    margin: 0 10px 0 0;
    transition: all ease-in-out 0.5s;

    @media (max-width: ${MOBILE_WIDTH}px) {
        margin: 0;
    }
`;

const InputArea = styled.textarea<{ isMissingValue: boolean | undefined }>`
    border-radius: 5px;
    border: ${(props) =>
        props.isMissingValue
            ? `1px solid ${Colors.red}`
            : `1px solid ${Colors.white}`};
    height: 200px;
    outline: none;
    padding: 10px 0 0 10px;
    margin: 0 10px 0 0;
    font-family: 'Poppins', sans-serif;
    resize: none;
    transition: all ease-in-out 0.5s;

    @media (max-width: ${MOBILE_WIDTH}px) {
        height: 90px;
        margin: 0;
    }
`;

export default TextInput;
