import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import { loopfiveApi } from '../api';
import Content from '../components/Content';
import Landing from '../components/Landing';
import Footer from '../components/sections/footer/Footer';
import WorkWithUs from '../components/sections/work-with-us/WorkWithUs';
import PageContainer from '../components/ui-elements/PageContainer';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import Navbar from '../navigation/Navbar';
import { close, selectIsOpened } from '../store/contact-form/contactFormSlice';
import { isDev, isFromAds } from '../utils/utils';

function Home() {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const [animationDone, setAnimationDone] = useState(false);
    const isWorkWithUsOpened = useAppSelector(selectIsOpened);

    function closeWorkWithUs() {
        if (isWorkWithUsOpened) {
            dispatch(close());
        }
    }

    useEffect(() => {
        if (!isDev() && isFromAds(location)) {
            loopfiveApi.sendMetaEvent('PageView');
        }
    }, []);

    return (
        <PageContainer>
            {animationDone ? (
                <div onClick={closeWorkWithUs}>
                    <Navbar />
                    <Content />
                    <Footer />
                </div>
            ) : (
                <AnimatedLanding
                    animate={{ opacity: 0 }}
                    transition={{ delay: 4, duration: 1, ease: 'easeInOut' }}
                    onAnimationComplete={() => setAnimationDone(true)}
                >
                    <Landing />
                </AnimatedLanding>
            )}
            <WorkWithUs />
        </PageContainer>
    );
}

const AnimatedLanding = styled(motion.div)`
    display: flex;
    flex: 1;
`;
export default Home;
